import { memo, useCallback } from 'react';
import { cn, List } from '@divlab/divanui';

import Link from '@Navigation/Link';
import Img from '@UI/Img';
import TripleCircle from './elems/TripleCircle';
import DoubleCircle from './elems/DoubleCircle';
import styles from './Fabrics.module.css';

import type { ColorData } from '@Types/Product';
import type { FC, HTMLAttributes, MouseEvent } from 'react';

export interface FabricsProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  fabrics: ColorData[];
  count?: number;
  defaultSelectedFabric?: ColorData;
  size?: 'xs' | 's' | 'm' | 'l';
  imageLazyLoading?: boolean;
  isLink?: boolean;
  onChangeProduct?: (id: number) => void;
  onVariantsLoad?: () => void;
  onClickColor?: (e: MouseEvent, clickedFabric: ColorData) => void;
  onClickConstructor?: () => void;
}

const Fabrics: FC<FabricsProps> = (props) => {
  const {
    className,
    fabrics,
    defaultSelectedFabric,
    size = 's',
    count,
    imageLazyLoading,
    isLink = true,
    onChangeProduct,
    onClickColor,
    onClickConstructor,
    ...restProps
  } = props;
  const handleChangeProduct = useCallback(
    (id: number) => {
      if (onChangeProduct) onChangeProduct(id);
    },
    [onChangeProduct],
  );

  const handleClickColor = useCallback(
    (e: MouseEvent, clickedFabric: ColorData) => {
      if (onClickColor) onClickColor(e, clickedFabric);
    },
    [onClickColor],
  );

  return (
    <List
      {...restProps}
      className={cn(styles.fabrics, className, {
        [styles.sizeXS]: size === 'xs',
        [styles.sizeS]: size === 's',
        [styles.sizeM]: size === 'm',
        [styles.sizeL]: size === 'l',
      })}
      items={fabrics}
      renderChild={(fabric: ColorData, index) => {
        const isLast = index === fabrics.length - 1;

        return (
          <div
            className={cn(styles.fabric, {
              [styles.selected]: fabric === defaultSelectedFabric,
              [styles.disabled]: !isLink,
            })}
          >
            <Link
              className={styles.fabricContainer}
              to={isLink ? fabric.link : undefined}
              onMouseEnter={() => handleChangeProduct(fabric.shopProductId)}
              onClick={(e: MouseEvent) => handleClickColor(e, fabric)}
              data-testid={`color${index + 1}`}
            >
              {(() => {
                switch (fabric.materials.length) {
                  case 1: {
                    return (
                      <div className={styles.imageWrapper}>
                        <Img
                          className={styles.image}
                          src={fabric.materials[0].image}
                          alt={fabric.alt}
                          loading={imageLazyLoading ? 'lazy' : 'eager'}
                        />
                      </div>
                    );
                  }

                  case 2: {
                    return (
                      <div className={styles.imageWrapper}>
                        <DoubleCircle
                          className={styles.image}
                          id={fabric.shopProductId}
                          materials={fabric.materials}
                        />
                      </div>
                    );
                  }

                  case 3: {
                    return (
                      <div className={styles.imageWrapper}>
                        <TripleCircle
                          className={styles.image}
                          id={fabric.shopProductId}
                          materials={fabric.materials}
                        />
                      </div>
                    );
                  }

                  default:
                    return null;
                }
              })()}
            </Link>
            {isLast && !!count && (
              <div
                className={styles.counter}
                onClick={onClickConstructor}
                data-testid='link-to-constructor-2'
              >
                {`+ ${count}`}
              </div>
            )}
          </div>
        );
      }}
    />
  );
};

export default memo(Fabrics);
